// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(max-width: 768px){.FW5zSXZtjmCvXSvq2zCu{margin:10px 32px}}@media(max-width: 440px){.FW5zSXZtjmCvXSvq2zCu{margin:10px 16px}}", "",{"version":3,"sources":["webpack://./src/client/assets/sass/base/_breakpoints.sass","webpack://./src/client/pages/User/pages/Account/styles.module.sass"],"names":[],"mappings":"AAWE,yBCTF,sBAEI,gBAAA,CAAA,CDGD,yBCLH,sBAII,gBAAA,CAAA","sourcesContent":["$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n","@import \"@/client/assets/sass/base/breakpoints\"\n\n.pageContainer\n  @include mobile\n    margin: 10px 32px    \n  @include small-mobile\n    margin: 10px 16px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "FW5zSXZtjmCvXSvq2zCu"
};
export default ___CSS_LOADER_EXPORT___;
