// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x1daJ6tSaj_s_Bx_Xzqk{bottom:0;left:0;position:absolute;right:0;top:0}.aIWhWbVMbVAPSWNWKOUw{display:flex;align-items:center;flex-direction:column;justify-content:center;overflow:hidden;z-index:40}.PLg29JSa8R7NfrSi2tO6{background-color:rgba(255,255,255,.86)}.LACGwKHWys9M273MpIMd{width:40%;z-index:50}", "",{"version":3,"sources":["webpack://./src/client/components/molecules/loaders/MintTokensLoader/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,QAAA,CACA,MAAA,CACA,iBAAA,CACA,OAAA,CACA,KAAA,CACF,sBACE,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,sBAAA,CACA,eAAA,CACA,UAAA,CACF,sBACE,sCAAA,CACF,sBACE,SAAA,CACA,UAAA","sourcesContent":[".general-background\n  bottom: 0\n  left: 0\n  position: absolute\n  right: 0\n  top: 0\n.default-loader\n  display: flex\n  align-items: center\n  flex-direction: column\n  justify-content: center\n  overflow: hidden\n  z-index: 40\n.default-loader__background\n  background-color: rgba(255, 255, 255, 0.86)\n.default-loader__container\n  width: 40%\n  z-index: 50"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"general-background": "x1daJ6tSaj_s_Bx_Xzqk",
	"default-loader": "aIWhWbVMbVAPSWNWKOUw",
	"default-loader__background": "PLg29JSa8R7NfrSi2tO6",
	"default-loader__container": "LACGwKHWys9M273MpIMd"
};
export default ___CSS_LOADER_EXPORT___;
