// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nP1LV3vSvAAwNG_LjwcC{width:100%;margin-top:16px;display:flex}@media(max-width: 768px){.nP1LV3vSvAAwNG_LjwcC{display:block}}.lLNHH5EJvBwTh7sE812p{width:50%}@media(max-width: 768px){.lLNHH5EJvBwTh7sE812p{width:100%}}.ptNWaQ_MfcQVm5WsPTB2{width:50%}@media(max-width: 768px){.ptNWaQ_MfcQVm5WsPTB2{width:100%}}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/Assets/components/LicenseFilters/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,eAAA,CACA,YAAA,CCMA,yBDTF,sBAKI,aAAA,CAAA,CACJ,sBACI,SAAA,CCEF,yBDHF,sBAGM,UAAA,CAAA,CACN,sBACI,SAAA,CCFF,yBDCF,sBAGM,UAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.licenseTypeContainer\n  width: 100%\n  margin-top: 16px\n  display: flex\n  @include mobile\n    display: block\n.licenseTypeNameContainer\n    width: 50%    \n    @include mobile\n      width: 100%\n.licenseOptionsContainer\n    width: 50%    \n    @include mobile\n      width: 100%      ","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"licenseTypeContainer": "nP1LV3vSvAAwNG_LjwcC",
	"licenseTypeNameContainer": "lLNHH5EJvBwTh7sE812p",
	"licenseOptionsContainer": "ptNWaQ_MfcQVm5WsPTB2"
};
export default ___CSS_LOADER_EXPORT___;
